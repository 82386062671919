<template>
  <b-overlay :show="loading">
    <div>
      <div>
        <section>
          <div class=" mt-md-5">
            <div class="mt-5 mb-3">
              <!-- services-->
              <div>
                 <h1 class="text-left mb-4 up-label">
                  Save The Dates
                </h1>

                <b-row>
                  <b-col class="mb-2" cols="12" md="12">
                    <list-events
                      :items="events"
                      :per-page="perPage"
                      :total-records="totalRecords"
                      :current-page.sync="currentPage"
                      detail-page="client-single-event"
                    />
                  </b-col>

                  <b-col cols="12" md="12">
                    <section>
                      <ClientBlogs />
                    </section>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { get } from "lodash"
import { BOverlay, BRow, BCol, BAlert, BLink, BButton, BCard, BImg } from 'bootstrap-vue'

import ListEvents from "@/@core/components/shared/events/ListEvents.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ClientBlogs from "./ClientBlogs.vue"

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BLink,
    BCard,
    BAlert,
    BButton,
    BOverlay,
    ClientBlogs,
    ListEvents
  },
  data(){
    return {
      loading: false,
      events: [],
      perPage: 50,
      currentPage: 1,
      totalRecords: 0,
    }
  },
  computed: {
    userData() {
      return this.$store.getters[`auth/userData`];
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
  },
  created(){
    this.fetchAllData()
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: 12,
          page: this.currentPage,
        }

        const request = await this.useJwt().sharedService.fetchEvents(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.events = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style lang="scss">
  .up-label {
    border-bottom: 10px solid rgb(2, 2, 119);
    padding-bottom: 10px;
    text-transform: uppercase;
  }
</style>
